<template>
  <div
    class="fixed w-full inset-0 bg-black/60 backdrop-blur-sm z-[999999]"
    @click="$emit('closeNotifications')"
  ></div>
  <div
    class="w-full max-w-[444px] fixed top-0 right-0 transition-all ease-in-out duration-300 bg-white dark:bg-neutral-900 z-[9999999]"
    :class="
      isLoaded
        ? 'right-0 translate-x-0  overflow-hidden'
        : '-right-full translate-x-full overflow-auto'
    "
  >
    <div
      class="py-5 px-6 flex flex-col gap-2 border-b border-neutral-300 dark:border-neutral-700"
    >
      <div class="w-full flex items-center justify-between gap-2">
        <h3 class="font-bold text-xl text-neutral-800 dark:text-neutral-200">
          Notifications
        </h3>
        <svg
          width="24"
          @click="$emit('closeNotifications')"
          height="24"
          viewBox="0 0 24 24"
          class="cursor-pointer text-neutral-800 dark:text-neutral-200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.64645 16.6464C6.45118 16.8417 6.45118 17.1583 6.64645 17.3536C6.84171 17.5488 7.15829 17.5488 7.35355 17.3536L6.64645 16.6464ZM12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464C12.1583 11.4512 11.8417 11.4512 11.6464 11.6464L12.3536 12.3536ZM11.6464 11.6464C11.4512 11.8417 11.4512 12.1583 11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536L11.6464 11.6464ZM17.3536 7.35355C17.5488 7.15829 17.5488 6.84171 17.3536 6.64645C17.1583 6.45118 16.8417 6.45118 16.6464 6.64645L17.3536 7.35355ZM12.3536 11.6464C12.1583 11.4512 11.8417 11.4512 11.6464 11.6464C11.4512 11.8417 11.4512 12.1583 11.6464 12.3536L12.3536 11.6464ZM16.6464 17.3536C16.8417 17.5488 17.1583 17.5488 17.3536 17.3536C17.5488 17.1583 17.5488 16.8417 17.3536 16.6464L16.6464 17.3536ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM7.35355 6.64645C7.15829 6.45118 6.84171 6.45118 6.64645 6.64645C6.45118 6.84171 6.45118 7.15829 6.64645 7.35355L7.35355 6.64645ZM7.35355 17.3536L12.3536 12.3536L11.6464 11.6464L6.64645 16.6464L7.35355 17.3536ZM12.3536 12.3536L17.3536 7.35355L16.6464 6.64645L11.6464 11.6464L12.3536 12.3536ZM11.6464 12.3536L16.6464 17.3536L17.3536 16.6464L12.3536 11.6464L11.6464 12.3536ZM12.3536 11.6464L7.35355 6.64645L6.64645 7.35355L11.6464 12.3536L12.3536 11.6464Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    <div v-if="!loadTime">
      <div
        v-if="notifications && notifications.length > 0"
        class="h-[calc(100vh-70px)] overflow-auto content-scroll p-6"
      >
        <div
          class="flex flex-col transition-all ease-in-out duration-500 gap-4 w-full"
        >
          <div
            :class="[
              loader == true &&
                notification.id == notificationID &&
                'opacity-20 transition-all ease-in-out duration-500',
            ]"
            class="flex flex-col gap-3 delay-200 transition-all ease-in-out duration-700 bg-white dark:bg-neutral-900"
            v-for="notification in notifications"
            :key="notification.id"
          >
            <div class="flex flex-col gap-2">
              <div class="flex items-center w-full justify-between">
                <div class="flex items-center gap-2">
                  <a
                      v-if="notification.attributes.type === 'analytics' && notification.attributes.data"
                      class="font-bold text-base capitalize text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                      :href="`/#/?${notification.id}`"
                      target="_blank"
                  >
                      {{ notification.attributes.title }}
                  </a>
                  <p
                      v-else-if="notification.attributes.type === 'export'"
                      class="font-bold text-base capitalize text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                      @click="startDownload(notification.attributes.nodeId, notification.attributes.data)"
                  >
                      {{ notification.attributes.title }}
                  </p>
                  <p
                    v-else
                    class="font-bold text-base capitalize text-neutral-900 dark:text-neutral-200"
                  >
                    {{ notification.attributes.title }}
                  </p>
                </div>
                <div
                  @click="
                    removeNotification(notification),
                      (notificationID = notification.id)
                  "
                  class="bg-red-50 hover:bg-red-100 p-1 rounded-full cursor-pointer"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3177_14140)">
                      <path
                        d="M6.99993 6.1751L9.88743 3.2876L10.7123 4.11243L7.82476 6.99993L10.7123 9.88743L9.88743 10.7123L6.99993 7.82476L4.11243 10.7123L3.2876 9.88743L6.1751 6.99993L3.2876 4.11243L4.11243 3.2876L6.99993 6.1751Z"
                        fill="#DC2626"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3177_14140">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div
                v-if="notification.attributes?.custom"
                class="flex items-center gap-2"
              >
                <img
                  class="w-5 h-5 lowercase"
                  :class="
                    JSON.parse(notification.attributes?.custom).type != 'VSL' &&
                    'rounded-full'
                  "
                  :src="`${cdnURL}${
                    JSON.parse(notification.attributes?.custom).type == 'VSL'
                      ? 'aevent'
                      : JSON.parse(notification.attributes?.custom).type
                  }.png`"
                  alt="item"
                />
                <span
                  class="text-sm font-semibold text-neutral-800 capitalize tracking-normal dark:text-neutral-400"
                >
                  {{
                    titleCaseType(
                      JSON.parse(notification.attributes?.custom).type
                    )
                  }}
                </span>
              </div>
              <span
                class="font-normal text-sm text-neutral-600 dark:text-neutral-400"
                >{{ notification.attributes.body }}</span
              >
            </div>
            <div class="w-full flex items-center justify-between">
              <button
                v-if="notification.attributes?.custom"
                @click="handleResolveIssue(notification.attributes?.custom)"
                class="underline text-blue-600 text-sm font-medium hover:text-blue-700 cursor-pointer transition-all ease-in-out duration-500"
              >
                Resolve Issue
              </button>
              <div class="flex items-center gap-1">
                <p
                  class="font-medium text-xs leading-none text-neutral-500 dark:text-neutral-400"
                >
                  {{
                    dayjs(notification.attributes.createdAt).format(
                      "MMM DD, YYYY"
                    )
                  }}
                </p>
                <div
                  class="w-1 h-1 rounded-full bg-neutral-500 dark:bg-neutral-400"
                ></div>
                <p
                  class="font-medium text-xs leading-none text-neutral-500 dark:text-neutral-400"
                >
                  {{
                    dayjs(notification.attributes.createdAt).format("h:mm A")
                  }}
                </p>
              </div>
            </div>
            <div
              class="border-b border-neutral-300 dark:border-neutral-700 w-full pt-3"
            ></div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col gap-4 h-[calc(100vh-70px)] items-center justify-center"
      >
        <img src="/icons/empty_notifications.png" class="w-52" />
        <h1
          class="font-semibold text-lg/none text-neutral-800 dark:text-neutral-200"
        >
          Your Notification Inbox is Empty
        </h1>
        <p class="font-medium text-sm text-neutral-600 dark:text-neutral-400">
          Any System or Account Notifications will appear here
        </p>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-center h-[calc(100vh-70px)]"
      v-else
    >
      <div class="loader"></div>
      <div
        class="text-base pt-6 text-neutral-600 whitespace-nowrap font-semibold dark:text-neutral-400"
      >
        Fetching Notifications...
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "~~/store/auth";
import dayjs from "dayjs";

// CDN set up
const config = useRuntimeConfig();
const cdnURL = config.public.cdnURL;
const isLoaded = ref(false);
const authStore = useAuthStore();
const notifications = ref([]);
const loader = ref(false);
const notificationID = ref("");
const loadTime = ref(false);
const router = useRouter();
const loadNotifications = async (notificationsFormAPI) => {
  let filterNotification = [];
  filterNotification = notificationsFormAPI;
  if (filterNotification && filterNotification.length > 0) {
    let finalNotifications = filterNotification.filter(
      (it) => it.attributes.color != "invisible"
    );
    if (finalNotifications && finalNotifications.length >= 0) {
      notifications.value = finalNotifications;
    }
  }
  loadTime.value = false;
};
onBeforeMount(async () => {
  getNotifications();
});

const getNotifications = async (chandeLoader) => {
  if (chandeLoader) {
    loader.value = true;
  } else {
    loadTime.value = true;
  }
  const initializeParse = setInterval(() => {
    if (window !== undefined && window?.Parse) {
      try {
        if (typeof window.Parse !== "undefined") {
          window.Parse.initialize(config.public.parseAppID);
          window.Parse.masterKey = config.public.parseMasterKey;
          window.Parse.serverURL = config.public.parseServerURL
          clearInterval(initializeParse);
          const newNotifications = window.Parse.Object.extend("Notifications");
          const query = new window.Parse.Query(newNotifications);
          query?.equalTo("userId", authStore.getUser?.user_uid || "");
          query?.descending("createdAt");
          query
            ?.find()
            .then((results) => {
              results?.some((notification) => {
                if (notification?.get("isNew") === true) {
                  return true;
                }
              });
              authStore.notifications = results;
              loadNotifications(results);
            })
            .catch((error) => {
              console.error("Error fetching notifications: ", error);
            });
        }
      } catch (e) {
        console.error("Could not connect to realtime: ", e);
      }
    }
  }, 1000);
};

const refresh = ref(false);
const handleRefresh = () => {
  refresh.value = true;
  setTimeout(() => {
    refresh.value = false;
  }, "800");
};

const removeNotification = (notification) => {
  notification.destroy().then(() => {
    let filteredNotifications = authStore.notifications.filter((item) => {
      return notification.id != item.id;
    });
    getNotifications(true);
  });
};

onMounted(() => {
  setTimeout(() => {
    isLoaded.value = true;
  }, 50);
});

const handleResolveIssue = (val) => {
  let id = JSON.parse(val).integrationID;
  router.push(`/integrations?reconnect=true&id=${id}`);
};

const startDownload = (filename, data = null) => {
  if (!data) {
    downloadFile(
      authStore.getUser.api_token,
      `${config.public.apiBaseURL}/webinar/download?downloadKey=${filename}`,
      filename
    );
  }
  if (!data.compressed) return;
  const compressed = data.compressed;
  const link = document.createElement("a");
  const blob = new Blob([decompress(compressed)], { type: 'text/csv' });
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}
</script>
<style scoped>
.loader {
  width: 50px;
  aspect-ratio: 1.154;
  position: relative;
  background: conic-gradient(
    from 120deg at 50% 64%,
    #0000,
    #2563eb 1deg 120deg,
    #0000 121deg
  );
  animation: l27-0 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  inset: 0;
  background: inherit;
  transform-origin: 50% 66%;
  animation: l27-1 1.5s infinite;
}

.loader:after {
  --s: -1;
}

@keyframes l27-0 {
  0%,
  30% {
    transform: rotate(0);
  }

  70% {
    transform: rotate(120deg);
  }

  70.01%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes l27-1 {
  0% {
    transform: rotate(calc(var(--s, 1) * 120deg)) translate(0);
  }

  30%,
  70% {
    transform: rotate(calc(var(--s, 1) * 120deg))
      translate(calc(var(--s, 1) * -5px), 10px);
  }

  100% {
    transform: rotate(calc(var(--s, 1) * 120deg)) translate(0);
  }
}
</style>
